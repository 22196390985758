import dlink from '../';
import { getProperThumbnailSize, getVideoUrlsAndMaxVideoSize } from '../../../../src/containers/Gallery/utils';

var _resize = [];
var imageSize;

function video(data, holder) {
	console.log("dlink video", data);
	var meta = data.metadata;
	var dynamicTitle;
	var template;

	// override

	if (dlink.code == 'XZJFuLZHB2zJ6b1CVJCTmCfv1iRPBBmwXr7') {
		data.country = 'sa';
	}

	template = HFN.renderTemplate('#dLinkVideo', {
		filename: meta.name,
		filesize: HFN.formatSize(meta.size)
	}).appendTo(holder);

	if ($.deparam.querystring().embed == 1) {
		template.addClass('embed');
	}

	//header title
	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');

	if (HFN.config.isMobile()) {
		if (!data.branding) {
			$('.header .filename').hide();
			// $('.header .bookmark').hide();
			// dynamic title
			dynamicTitle = dlink.dynamicTitle(meta);
			template.find('._title').append(dynamicTitle.template);
			_resize.push(dynamicTitle.resize);
		}

		dlink.addMobileButton(dlink.createShareButtonMobile());
		if (data.candownload ) {
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
		template.find('.button-area').remove();
	} else {
		if (data.candownload) {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
			dlink.addDesktopButton(dlink.getDownloadHeaderButton());
		}
	}
	_loadVideoPlayer(data, template.find('.player')[0]);

	$(window).on('resize', resize);
}

export default video;

/**********************************/
/* 			  Helpers			  */
/**********************************/

function _loadVideoPlayer(data, node, autoPlay = false, seek = 0) {
	var videoData = {
		id: data.metadata.id,
		name: data.metadata.name,
		videoUrls: [],
		thumbUrl: "",
		thumbSize: "",//<width>x<height>
		thumbType: "image/jpeg",
		subUrl: "",
		maxWidth: 0,
		maxHeight: 0
	};

	var {videoUrls, videoMaxWidth, videoMaxHeight} = getVideoUrlsAndMaxVideoSize(data.variants, false);
	
	if (videoUrls.length === 0) {
		showErrorLoadingVideo();
		return;
	}

	videoData.videoUrls = videoUrls;
	videoData.maxWidth = videoMaxWidth;
	videoData.maxHeight = videoMaxHeight;
	
	var thumbWidth = getProperThumbnailSize(Math.min(videoData.maxWidth, Math.max(window.screen.availWidth, window.screen.availHeight)));
	var thumbHeight = getProperThumbnailSize(Math.min(videoData.maxHeight, Math.max(window.screen.availWidth, window.screen.availHeight)));
	videoData.thumbUrl = dlink.imageUrl(thumbWidth, thumbHeight);
	videoData.thumbSize = thumbWidth + "x" + thumbHeight;

	var videoContainerRemove = prc.VideoContainer(node, {
		ref: {},
		videoData,
		autoPlay,
		seek,
		noSwipeGesture: true,
		defaultDocumentTitle: document.title,
		setVideoLoading: toggleLoading,
		onPlay: () => {
			delete data.refreshedVideoLinks;
		},
		onError: ({autoPlay, mediaType, lastPlayingTime}) => {
			if (mediaType === "videoThumb") {
				// Broken video thumb. Keep showing the play button on black container.
				return;
			}

			if (data.refreshedVideoLinks) {
				showErrorLoadingVideo();
				return;
			}
			
			// Reload video links.
			toggleLoading(true);
			
			videoContainerRemove();
			HFN.apiMethod('getpubvideolinks', { code: dlink.code, linkpassword: dlink.linkpassword }, function (vlinksret) {
				data.refreshedVideoLinks = true;
				data.variants = vlinksret.variants;
				_loadVideoPlayer(data, node, autoPlay, lastPlayingTime);
			}, {
				errorCallback: function (ret) {
					showErrorLoadingVideo();
				}
			})
		}
	});

	return videoData;
}

function toggleLoading(loading) {
	if(loading) {
		$(".player").addClass("loading");
	} else {
		$(".player").removeClass("loading");
	}
}

function showErrorLoadingVideo() {
	$(".player").empty().append(HFN.renderTemplate('#dLinkVideoError'));
}

function resize() {
	new DelayedExecution(function () {
		_resize.forEach(function (eachResize) {
			eachResize();
		});
	}, 80, 'img-resize');
}
