import dlink from "../";
import { isSameLocation } from "../../utils";

var _resize = [];

export default function show(data, holder) {
	console.log("dlink generic");
	var meta = data.metadata;
	var dynamicTitle = dlink.dynamicTitle(meta);
	var template;

	template = HFN.renderTemplate('#dLinkGeneric', {
		filename: meta.name,
		filesize: HFN.formatSize(meta.size)
	}).appendTo(holder);

	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');

	if (!data.branding) {
		$('.header .filename').hide()
		// dynamic title
		template.find('._title').append(dynamicTitle.template);
		_resize.unshift(dynamicTitle.resize);
	}

	var iconwrap = $('<div class="iconwrap"></div>');
	var icon = HFN.createIcon(HFN.metaIcon(meta), HFN.ICONS.OVERTRAFFIC_PUBLINK);

	iconwrap.append(icon);
	template.find('.icon').append(iconwrap);

	if (data.candownload) {
		if (isSameLocation()) {
			template.find('.button-area').append(dlink.getSaveButton());
		}
		template.find('.button-area').append(dlink.getDownloadButton());

		if (HFN.config.isMobile()) {
			dlink.addMobileButton(dlink.createShareButtonMobile());
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		} else {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
		}
	}

	$(window).on('resize', windowResize);
	windowResize();
}

_resize.push(function () {
	dlink.centralizeDlContent('#dl_content .generic');
});

function windowResize() {
	new DelayedExecution(function () {
		_resize.forEach(function (eachResize) {
			eachResize();
		});
	}, 10, 'generic-resize');
}
