import dlink from '../';

var imageSize;
var _resize = [];

export default function image(data, holder) {
	console.log("dlink image");

	var meta = data.metadata;
	var maxWidth = Math.max(window.screen.availWidth, window.screen.availHeight);
	var maxHeight = Math.max(window.screen.availWidth, window.screen.availHeight);
	var thumbSize = imageSize = HFN.calcImageSize(meta.width || 1024, meta.height || 1024, maxWidth, maxHeight);
	var thumb = $('<img>', { src: dlink.imageUrl(thumbSize[0], thumbSize[1]) });
	var dynamicTitle;

	var template = HFN.renderTemplate('#dLinkImage', { filename: meta.name, filesize: HFN.formatSize(meta.size) })
		.appendTo(holder)
		.find('.thumb').addClass('responsive-thumb')
			.append(thumb)
			.append(thumb)
		.end()

	//header title
	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');
	
	if (HFN.config.isSite()) {
		if (data.candownload) {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
			dlink.addDesktopButton(dlink.getDownloadHeaderButton());
		}
	} else {
		if (!data.branding) {
			$('.header .filename').hide();
			// dynamic title
			dynamicTitle = dlink.dynamicTitle(meta);
			template.find('._title').append(dynamicTitle.template);
			_resize.push(dynamicTitle.resize);
		}
		if (data.candownload) {
			dlink.addMobileButton(dlink.createShareButtonMobile());
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
	}

	$(window).on('resize', windowResize);

	thumb[0].onload = function() {
		imageSize = [this.naturalWidth, this.naturalHeight];

		template.find('.loading').hide();
		template.find('.thumb').show();

		resizeArea();
	}

	thumb[0].onerror = function() {
		dlink.go("generic");
	}
}

/**********************************/
/* 			  Helpers			  					*/
/**********************************/

function padHeight() {

	if (HFN.config.isMobile())
		return $('div.header').outerHeight();

	var hpad = 0;
	hpad += $('div.header-wrap').outerHeight();
	hpad += $('._title').outerHeight();
	hpad += 40;
	hpad += $('.button-area').outerHeight(); // download/save buttons

	return hpad;
}

function padWidth() {
	var wpad = 0;

	if ($('.dl-sidebar').length && 0) {
		wpad += $('.dl-sidebar').outerWidth();
		wpad += 100;
	}

	return wpad;
}

function resizeArea() {
	var hpad = padHeight();
	var wpad = padWidth();
	var heightMax = 1700;
	var widthMax = 1700;

	console.log('hpad', hpad);
	console.log('wpad', wpad);
	console.log('outerheight', $(window).outerHeight());

	var size = HFN.calcImageSize(
		imageSize[0], imageSize[1],
		//$(window).outerWidth(),
		Math.min(widthMax, $(window).outerWidth() - wpad),
		Math.min(heightMax, $(window).outerHeight() - hpad)
	);
	var cssobj = {
		width: size[0],
		height: size[1]
	};

	$('.thumb').css(cssobj);
	$('.thumb > img').css(cssobj);

	dlink.centralizeDlContent('#dl_content .image');
}

_resize.push(resizeArea);

function windowResize() {
	new DelayedExecution(function () {
		_resize.forEach(function (eachResize) {
			eachResize();
		});
	}, 80, 'img-resize');
}
